import React, {Fragment, useEffect} from 'react'
import './filter.scss'
import {getSiblings} from '../utility/offerFunctions'

const Filter = (props) => {
    const {filterType, contentType, setContentType, setModel, model} = props

    const filterClick = (e, setModel, setContentType) => {
        let siblings = getSiblings(e.currentTarget)
        siblings.map(sibling => {
            if (sibling.classList.contains('selected')) {
                sibling.classList.remove('selected')
                e.currentTarget.classList.add('selected')
                setContentType(e.currentTarget.id)
                window.scrollTo(0, document.getElementsByClassName("offers__container")[0].offsetTop - document.getElementById("header").firstChild.offsetHeight)
                if (e.currentTarget.id === 'Model') {
                    let newModel = e.currentTarget.getAttribute('name')
                    return setModel(newModel)
                }
        
                return setModel('none')
            }
        })
    }

    const formatFilter = (filterType) => {
        if (filterType === 'offMSRP') return 'Additional Savings';
        const newStr = filterType.replace(/([a-z])([A-Z])/g, '$1 $2')
        const arr = newStr.split(' ')
        if (arr.length !== 1) {
            return newStr
        }

        return filterType
    }

    return (
        <Fragment>
            {filterType === 'All' ?
                <button id={filterType} className={filterType === contentType ? `filter__button selected` : `filter__button`} onClick={(e) => filterClick(e, setModel, setContentType)}>ALL OFFERS <span className="arrow"/></button> :
            (filterType !== 'All' && filterType !== 'placeholder' && filterType !== 'Lease' && filterType !== 'Finance' && filterType !== 'offMSRP' && filterType !== 'managerFinance' && filterType !== 'managerLease') ?
                <button id='Model' name={filterType} className={model === filterType ? `filter__button selected` : `filter__button`} onClick={(e) => filterClick(e, setModel, setContentType)}>{filterType} <span className="arrow"/></button> :
            filterType === 'placeholder' ? '' :
                <button id={filterType} className={filterType === contentType ? `filter__button selected` : `filter__button`} onClick={(e) => filterClick(e, setModel, setContentType)}>{formatFilter(filterType)}<span className="arrow"/></button>
            }
        </Fragment>
    )
}

export default Filter